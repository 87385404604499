import React from 'react'
import globalStyle from '../css_modules/global.module.scss'
import teamSection from '../css_modules/teamsection.module.scss'

const TeamSection = () => {
    return (
        <section className={teamSection.teamSection}>
            <div className={globalStyle.container}>
                <h2 className={globalStyle.heroTitle}>Find out about us and<br />
                    <label> reach out</label>
                </h2>
                <div className={teamSection.teamContainer}>
                    <ul className={teamSection.teamList}>
                        <li className={teamSection.teamMember1}>
                            <div className={teamSection.overlay}><p>Julia Tanasic</p></div>
                            <a href='https://www.linkedin.com/in/juliatanasic'>
                            </a>
                        </li>
                        <li className={teamSection.teamMember4}>
                            <div className={teamSection.overlay}><p>Kristian Nikolic</p></div>
                            <a href='https://www.linkedin.com/in/kristian-nikolic-4a4807136/'>
                            </a>
                        </li>
                    </ul>
                    <ul className={teamSection.teamList}>
                        <li className={teamSection.teamMember5}>
                            <div className={teamSection.overlay}><p>Kira-Lea Crabtree</p></div>
                            <a href='https://www.linkedin.com/in/kira-lea-crabtree/'>
                            </a>
                        </li>
                        <li className={teamSection.teamMember3}>
                            <div className={teamSection.overlay}><p>Claudia Scheit</p></div>
                            <a href='#'>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

    )
}

export default TeamSection