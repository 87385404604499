import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutHeroSection from "../components/about/abouthero";
import HomeNewsletterSection from "../components/homepage/homenewsletter";
import AboutFirstSection from "../components/about/aboutfirst";
import TeamSection from "../components/about/team";
import WrapupsSection from "../components/about/wrapupsvtwo";

const OurStory = () => (
  <Layout>
    <SEO title="Our Story | Digital Madness" />
    <AboutHeroSection />
    <AboutFirstSection />
    <WrapupsSection />
    <TeamSection />
    <HomeNewsletterSection />
  </Layout>
)

export default OurStory
