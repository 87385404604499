import React from 'react'
// import { Link } from "gatsby"
import homeServices from '../css_modules/homeservices.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import aboutFirst from '../css_modules/aboutfirst.module.scss'

const AboutFirstSection = () => {
    return (
        <section className={homeServices.homeServicesSection + ' ' + aboutFirst.mainSection}>
            <div className={homeServices.sectionLeft}>
                <div className={globalStyle.container + ' ' + aboutFirst.visionValues}>
                    <h2 className={globalStyle.heroTitle}>Our vision<br /><label> and values</label></h2>
                    <p>
                        <li><div className={aboutFirst.firstLi}></div>We support you in being authentic and real with your customers, clients and partners</li>
                        <li><div></div>We help you build and market successful products and services and get it to the right people</li>
                        <li><div></div>Expect sincere feedback and a committed way forward with us</li>
                        <li><div></div>Because strong relationships and partnerships matter to us, we will always be interested in a
long term and effective solution for you</li>
                    </p>
                </div>
                <div className={aboutFirst.monthlyVideo}>
                    <div className={globalStyle.container}>
                        <h2 className={globalStyle.heroTitle}>Dive into our working month<br /><label> monthly wrap up</label></h2>
                        <div className={aboutFirst.mainVideo}>
                            <div className={aboutFirst.iframeHolder}>
                                <iframe title="DigtalMadness promo video" width="100%" height="720" src="https://www.youtube.com/embed/fiIvXwkM0sg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutFirstSection