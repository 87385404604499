import React from 'react'
// import { Link } from "gatsby"
import homeServices from '../css_modules/homeservices.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import wrapupsSection from '../css_modules/wrapupssection.module.scss'
import servicesMain from '../css_modules/servicesmain.module.scss'

const WrapupsSection = () => {

    return (
        <section className={homeServices.homeServicesSection + ' ' + wrapupsSection.mainSection}>
            <div className={homeServices.sectionLeft}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>Dive into our working week<br />
                        <label>Weekly wrap ups 2019</label>
                    </h2>
                </div>
                <div className={globalStyle.grayBackground + ' ' + servicesMain.grayBackground}>
                    <div className={wrapupsSection.container}>
                        <div className={wrapupsSection.faders}>
                            <div className={wrapupsSection.left} />{/* /.left */}
                            <div className={wrapupsSection.right} />{/* /.right */}
                        </div>{/* /.faders */}
                        <div className={wrapupsSection.items}>
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 16</p>
                                <iframe title="Weekly wrapup episode 16" width="100%" height="350px" src="https://www.youtube.com/embed/zbdbcAKpY6k?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 15</p>
                                <iframe title="Weekly wrapup episode 15" width="100%" height="350px" src="https://www.youtube.com/embed/ID5aMeIy-pw?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 14</p>
                                <iframe title="Weekly wrapup episode 14" width="100%" height="350px" src="https://www.youtube.com/embed/zbdbcAKpY6k?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 13</p>
                                <iframe title="Weekly wrapup episode 13" width="100%" height="350px" src="https://www.youtube.com/embed/r7B62RrpIJk?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 12</p>
                                <iframe title="Weekly wrapup episode 12" width="100%" height="350" src="https://www.youtube.com/embed/F6d8yB697sc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 11</p>
                                <iframe title="Weekly wrapup episode 11" width="100%" height="350" src="https://www.youtube.com/embed/7wBv8-H1lZw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                            <div className={wrapupsSection.entry}>
                                <p className={wrapupsSection.name}>Episode 10</p>
                                <iframe title="Weekly wrapup episode 10" width="100%" height="350" src="https://www.youtube.com/embed/nppVsoAExHI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>{/* /.entry */}
                        </div>{/* /.items */}
                    </div>{/* /.container */}
                    <div className={homeServices.sectionRight}>
                        <div className={homeServices.imgHolder + ' ' + servicesMain.imgHolder + ' ' + wrapupsSection.imgHolder}></div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default WrapupsSection