import React from 'react'
import homeHero from '../css_modules/homehero.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import aboutHero from '../css_modules/abouthero.module.scss'
import { Link } from "gatsby"

const AboutHeroSection = () => {
    return (
        <section className={homeHero.heroSectionHome + ' ' + aboutHero.heroSectionAbout}>
            <div className={globalStyle.container}>
                <h1 className={globalStyle.h1Hero}>Find out <br /><label>about us</label></h1>
            </div>
            <div className={homeHero.heroSectionContent}>
                <div className={homeHero.imgPlaceholder + ' ' + aboutHero.imgPlaceholder}>
                    <iframe title='About DigitalMadness' width="100%" height="100%" src="https://www.youtube.com/embed/eGACBNLkZ28" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className={homeHero.contentRight}>
                    <p>WE ARE DIGITAL MADNESS. From digital strategy to content marketing to marketing technologies and everything in between.
Our focus is to help prepare businesses to evolve and adapt to the digital age of business and marketing. </p>
                    <Link to="contact" className={globalStyle.mainButton}>Talk to us</Link>
                </div>
            </div>
        </section >
    )
}

export default AboutHeroSection